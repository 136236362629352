export default {
  install: (Vue) => {
    //private
    const route = '/api/state/';

    //public
    Vue.$stateService = {
      async find(params) {
        const states = JSON.parse(sessionStorage.getItem('states'));

        if (states) {
          return states;
        }

        const res = await Vue.axios.get(route, params);
        window.sessionStorage.setItem(
          'states',
          JSON.stringify(res)
        );

        return res;
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      }
    };
  }
};