import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import VeeValidate from 'vee-validate';
import vueDebounce from 'vue-debounce';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router/router';

import DateFilter from '../shared/filters/date';
import PersonNameFilter from '../shared/filters/personName';
import PhoneNumberFilter from '../shared/filters/phoneNumber';

import AccountService from '../shared/services/accountService';
import AgencyService from '../shared/services/agencyService';
import AppEventBus from '../shared/appEventBus';
import CityService from '../shared/services/cityService';
import ClientService from '../shared/services/clientService';
import GenderService from '../shared/services/genderService';
import HouseholdService from '../shared/services/householdService';
import StateService from '../shared/services/stateService';
import UserService from '../shared/services/userService';

Vue.use(Vuetify, {
  theme: {
    primary: '#2196F3',
    secondary: '#BBDEFB',
    accent: '#4CAF50',
    error: '#F44336'
  }
});
Vue.use(vueDebounce);
Vue.use(VeeValidate);
Vue.use(VueAxios, axios);

Vue.use(AppEventBus);
Vue.use(AccountService);
Vue.use(AgencyService);
Vue.use(CityService);
Vue.use(ClientService);
Vue.use(GenderService);
Vue.use(HouseholdService);
Vue.use(StateService);
Vue.use(UserService);

Vue.filter('date', DateFilter);
Vue.filter('personName', PersonNameFilter);
Vue.filter('phoneNumber', PhoneNumberFilter);

VeeValidate.Validator.extend('verify_password', {
  getMessage: () => 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  validate: value => {
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,])(?=.{8,})');
      return strongRegex.test(value);
  }
});

//everything requires authentication in the app. else kick em out.
//const authToken = JSON.parse(sessionStorage.getItem('accessToken'));
//
//if (!authToken) {
//  window.location = '/account/auth';
//  // delete the token from the common headers
//  delete Vue.axios.defaults.headers.common['x-access-token'];
//}
Vue.axios.get('/account/management/user')
  .then(response => {
    window.sessionStorage.setItem(
      'user',
      JSON.stringify(response.data)
    );
  }, error => {
    if (error.response.status === 401) {
      window.location = '/account/auth';
    }
  })
  .then(() => {
    new Vue({
      el: '#app',
      router,
      template: '<App/>',
      components: {
        App
      }
    });
  });
