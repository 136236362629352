var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitPassword($event)
            }
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:6|verify_password",
                            expression: "'required|min:6|verify_password'"
                          }
                        ],
                        ref: "password",
                        attrs: {
                          "prepend-icon": "lock",
                          label: "Password",
                          type: "password",
                          disabled: _vm.sending,
                          "error-messages": _vm.errors.collect("Password"),
                          "data-vv-name": "Password",
                          required: ""
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "confirmed:password",
                            expression: "'confirmed:password'"
                          }
                        ],
                        attrs: {
                          "prepend-icon": "lock",
                          label: "Confirm Password",
                          type: "password",
                          disabled: _vm.sending,
                          "error-messages": _vm.errors.collect(
                            "Confirm Password"
                          ),
                          "data-vv-name": "Confirm Password",
                          "data-vv-as": "Password",
                          required: ""
                        },
                        model: {
                          value: _vm.confirmPassword,
                          callback: function($$v) {
                            _vm.confirmPassword = $$v
                          },
                          expression: "confirmPassword"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-xs-right primary",
                      attrs: { xs12: "" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            type: "submit",
                            color: "white",
                            disabled: _vm.sending
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }