<template>
  <v-card>
    <v-card-title primary-title>
      <div>
        <h3 class="headline">Account Management</h3>
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs fixed-tabs>
        <v-tab>Details</v-tab>
        <v-tab>Password</v-tab>

        <v-tab-item>
          <DetailForm v-bind:agencies="agencies" v-bind:user="user" />
        </v-tab-item>
        <v-tab-item>
          <PasswordForm />
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<style>
</style>

<script>
import DetailForm from "./DetailForm";
import PasswordForm from "./PasswordForm";
import Vue from "vue";

export default {
  name: "Management",
  async beforeRouteEnter(to, from, next) {
    try {
      let agencies = await Vue.$agencyService.find();
      let ret = await Vue.$accountService.current();
      return next(vm => {
        vm.agencies = agencies.data;
        vm.user = ret;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  components: { DetailForm, PasswordForm },
  data() {
    return {
      agencies: [],
      edit: false,
      user: {}
    };
  }
};
</script>
