var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("h3", { staticClass: "headline" }, [_vm._v("Clients")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-text-field", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:500ms",
                value: _vm.searchClients,
                expression: "searchClients",
                arg: "500ms"
              }
            ],
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.searchText,
              callback: function($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.clients,
              "total-items": _vm.clientsCount,
              loading: _vm.loading,
              pagination: _vm.pagination,
              "rows-per-page-items": _vm.rowsPerPageItems,
              "item-key": "id"
            },
            on: {
              "update:pagination": [
                function($event) {
                  _vm.pagination = $event
                },
                _vm.updateResults
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c(
                      "td",
                      { staticClass: "my-grid-group" },
                      [
                        _vm.user.isAdmin
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "red lighten-5 my-grid-button",
                                attrs: { flat: "", color: "error", icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.promptDelete(props.item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "blue lighten-5 my-grid-button",
                            attrs: {
                              flat: "",
                              color: "primary",
                              to: {
                                name: "client",
                                params: {
                                  clientId: props.item.id,
                                  householdId: props.item.householdId
                                }
                              },
                              icon: ""
                            }
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.code))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.firstName))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.lastName))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          props.item.household
                            ? props.item.household.firstName +
                                " " +
                                props.item.household.lastName
                            : ""
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.gender.name))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm._f("date")(props.item.dob)))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.ssn))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm._f("date")(props.item.intakeDate)))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.comments))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.agency.name))])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey lighten-2",
                  attrs: { "primary-title": "" }
                },
                [_vm._v("Delete " + _vm._s(_vm.deleteClient.fullName))]
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "Are you sure you want to delete " +
                    _vm._s(_vm.deleteClient.fullName) +
                    "?"
                )
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.doDelete(_vm.deleteClient)
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }