export default {
  install: (Vue) => {
    //private
    const route = '/api/agency/';
    const put = (id, agency, params) => Vue.axios.put(`${route}${id}`, agency, params);
    const del = (id, agency, params) => Vue.axios.delete(`${route}${id}`, agency, params);
    const post = (agency, params) => Vue.axios.post(route, agency, params);

    //public
    Vue.$agencyService = {
      map(agencies) {
        agencies = agencies && agencies.data ? agencies.data : agencies;

        if (agencies) {
          agencies.map(agency => {
            agency.cityName = agency.city ? agency.city.name : '';
            agency.stateName = agency.state ? agency.state.name : '';
            agency.phoneNumber = agency.phoneNumber ? `(${agency.phoneNumber.substr(0, 3)}) ${agency.phoneNumber.substr(3, 3)} - ${agency.phoneNumber.substr(6)}` : '';

            return agency;
          });
        }
        return agencies;
      },
      find(params) {
        return Vue.axios.get(route, params);
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      },

      save(agency, params) {
        if (agency.state) agency.stateId = agency.state.id;
        if (agency.city) agency.cityId = agency.city.id;

        if (agency.id) {
          return put(agency.id, agency, params);
        }

        return post(agency, params);
      },

      delete(agency) {
        return del(agency.id, agency, {});
      }
    };
  }
};