<template>
  <v-card>
    <v-card-title>
      <h3 class="headline">Agencies</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-btn color="accent" :to="{ name: 'agency', params: {agencyId: 0}}">Add</v-btn>
      <v-data-table :headers="headers" :items="agencies" :search="search" item-key="id">
        <template slot="items" slot-scope="props">
          <td>
            <v-btn
              flat
              color="error"
              class="red lighten-5 my-grid-button"
              @click="promptDelete(props.item)"
              icon
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              flat
              color="primary"
              class="blue lighten-5 my-grid-button"
              :to="{ name: 'agency', params: {agencyId: props.item.id}}"
              icon
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
          <td>{{ props.item.sourceCode }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.phoneNumber }}</td>
          <td>{{ props.item.phoneExtension }}</td>
          <td>{{ props.item.addressLine1 }}</td>
          <td>{{ props.item.stateName }}</td>
          <td>{{ props.item.cityName }}</td>
          <td>{{ props.item.zip }}</td>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Delete {{deleteAgency.name}}</v-card-title>

        <v-card-text>
          Are you sure you want to delete {{deleteAgency.name}}         
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" flat text @click="doDelete(deleteAgency)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style>
</style>

<script>
import Vue from "vue";

export default {
  name: "agencies",
  async beforeRouteEnter(to, from, next) {
    try {
      let agencies = await Vue.$agencyService.find();

      agencies = Vue.$agencyService.map(agencies);

      return next(vm => {
        vm.agencies = agencies;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  data() {
    return {
      agencies: [],
      deleteDialog: false,
      deleteAgency: {},
      headers: [
        { text: "Actions", sortable: false, value: "id" },
        { text: "Source Code", value: "sourceCode" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phoneNumber" },
        { text: "Extension", value: "phoneExtension" },
        { text: "Address 1", value: "addressLine1" },
        { text: "State", value: "stateName" },
        { text: "City", value: "cityName" },
        { text: "Zip", value: "zip" }
      ],
      search: "",
      sending: false
    };
  },
  methods: {
    async doDelete(agency) {
      this.send(true);

      try {
        let res = await Vue.$agencyService.delete(agency);
        this.send(false);
        this.deleteDialog = false;
        Vue.$eventBus.$emit("notifyUser", "Agency Deleted Successfully!");
        this.$router.go();
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    promptDelete(agency) {
      this.deleteDialog = true;
      this.deleteAgency = agency;
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    }
  }
};
</script>