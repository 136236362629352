var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c("div", [
          _c("h3", { staticClass: "headline" }, [_vm._v("Account Management")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            { attrs: { "fixed-tabs": "" } },
            [
              _c("v-tab", [_vm._v("Details")]),
              _vm._v(" "),
              _c("v-tab", [_vm._v("Password")]),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c("DetailForm", {
                    attrs: { agencies: _vm.agencies, user: _vm.user }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-tab-item", [_c("PasswordForm")], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }