<template>
  <v-card>
    <v-card-title>
      <h3 class="headline">Clients</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="searchText" v-debounce:500ms="searchClients" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table 
        :headers="headers" 
        :items="clients" 
        :total-items="clientsCount"
        :loading="loading"
        :pagination.sync="pagination"
        :rows-per-page-items="rowsPerPageItems"
        @update:pagination="updateResults"
        item-key="id">
        <template slot="items" slot-scope="props">
          <td class="my-grid-group">
            <v-btn
              flat
              color="error"
              class="red lighten-5 my-grid-button"
              @click="promptDelete(props.item)"
              icon
              v-if="user.isAdmin"
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              flat
              color="primary"
              class="blue lighten-5 my-grid-button"
              :to="{ name: 'client', params: {clientId: props.item.id, householdId: props.item.householdId}}"
              icon
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
          <td>{{ props.item.code }}</td>
          <td>{{ props.item.firstName }}</td>
          <td>{{ props.item.lastName }}</td>
          <td>{{ props.item.household ? `${props.item.household.firstName} ${props.item.household.lastName}` : '' }}</td>
          <td>{{ props.item.gender.name }}</td>
          <td>{{ props.item.dob | date}}</td>
          <td>{{ props.item.ssn }}</td>
          <td>{{ props.item.intakeDate | date}}</td>
          <td>{{ props.item.comments }}</td>
          <td>{{ props.item.agency.name }}</td>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Delete {{deleteClient.fullName}}</v-card-title>

        <v-card-text>Are you sure you want to delete {{deleteClient.fullName}}?</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" flat text @click="doDelete(deleteClient)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style>
</style>

<script>
import Vue from "vue";
import moment from "moment";

export default {
  name: "clients",
  async beforeRouteEnter(to, from, next) {
    try {
      let user = await Vue.$accountService.current();

      return next(vm => {
        vm.user = user;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  data() {
    return {
      clients: [],
      clientsCount: null,
      deleteDialog: false,
      loading: false,
      pagination: {},
      deleteClient: {},
      rowsPerPageItems: [5, 10, 25, 50],
      headers: [
        { text: "Actions", sortable: false, value: "id" },
        { text: "Name SSN Code", value: "code" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Household", value: "household.fullName" },
        { text: "Gender", value: "gender.name" },
        { text: "DOB", value: "dob" },
        { text: "SSN", value: "ssn" },
        { text: "Intake Date", value: "intakeDate" },
        { text: "Comments", value: "comments" },
        { text: "Agency", value: "agency.name" }
      ],
      searchText: '',
      sending: false,
      user: {}
    };
  },
  methods: {
    async doDelete(client) {
      this.send(true);

      try {
        let res = await Vue.$clientService.delete(client);
        this.send(false);
        this.deleteDialog = false;
        Vue.$eventBus.$emit("notifyUser", "Client Deleted Successfully!");
        this.$router.go();
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    promptDelete(client) {
      this.deleteDialog = true;
      this.deleteClient = client;
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    },
    async searchClients(searchText) {
      this.pagination.page = 1;
      await this.updateResults(this.pagination, searchText)
    },
    async updateResults(paginationInfo, searchText) {
      this.loading = true;
      let clientsInfo = await Vue.$clientService.find({
        params: {
          limit: paginationInfo.rowsPerPage,
          offset: (paginationInfo.page - 1) * paginationInfo.rowsPerPage,
          orderBy: paginationInfo.sortBy,
          descending: paginationInfo.descending,
          text: searchText || this.searchText
        }
      });
      this.clients = clientsInfo.data.rows;
      this.clientsCount = clientsInfo.data.count;
      this.loading = false;
    }
  }
};
</script>