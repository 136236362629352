<template>
  <v-hover>
    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`">
      <v-card-title>
        <div>
          <div class="headline">404 - Page Not Found</div>
          <span class="grey--text">APIC</span>
        </div>
      </v-card-title>
      <v-card-text class="body-2">Sorry we could not find that page.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" :to="{name:'account'}">Home</v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<style scoped>
@media (min-width: 600px) {
  .md-card {
    margin: 20px;
  }
}

@media (max-width: 601px) {
  .md-card {
    margin: 5px;
  }
}
</style>

<script>
import Vue from "vue";

export default {
  name: "notFound"
};
</script>