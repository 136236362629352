<template>
  <v-card>
    <v-card-title>
      <v-btn
        small
        flat
        icon
        :to="{ name: 'household', params: { householdId: client.householdId}}"
        v-if="showHouseholdNav"
      >
        <v-icon medium>arrow_left</v-icon>
      </v-btn>
      <v-btn small flat icon :to="{ name: 'clients'}" v-if="!showHouseholdNav">
        <v-icon medium>arrow_left</v-icon>
      </v-btn>
      <span class="title font-weight-light">
        Back to
        <span v-if="!showHouseholdNav">Clients</span>
        <span v-if="showHouseholdNav">Household</span>
      </span>
    </v-card-title>
    <v-card-text>
      <h3 class="headline" style="text-align: center">{{client | personName}}</h3>
      <v-form v-on:submit.prevent="duplicateCheck">
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Name SSN Code"
                type="text"
                :disabled="sending || true"
                v-validate="'required'"
                :error-messages="errors.collect('Name SSN Code')"
                data-vv-name="Name SSN Code"
                required
                maxlength="7"
                v-model="client.code"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="First Name"
                type="text"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('First Name')"
                data-vv-name="First Name"
                required
                v-model="client.firstName"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Last Name"
                type="text"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('Last Name')"
                data-vv-name="Last Name"
                required
                v-model="client.lastName"
                @change="updateCode()"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-autocomplete
                v-model="client.gender"
                :items="genders"
                item-text="name"
                label="Gender"
                persistent-hint
                return-object
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('Gender')"
                data-vv-name="Gender"
                required
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-menu
                :close-on-content-click="false"
                v-model="menu2"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="client.dob"
                  label="Date of Birth"
                  prepend-icon="event"
                  readonly
                  :disabled="sending"
                  v-validate="'required'"
                  :error-messages="errors.collect('Date of Birth')"
                  data-vv-name="Date of Birth"
                  required
                ></v-text-field>
                <v-date-picker v-model="client.dob" @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Clothing Size"
                type="text"
                :disabled="sending"
                v-model="client.clothingSize"
              ></v-text-field>
            </v-flex>
            
            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Gift Ideas"
                type="text"
                :disabled="sending"
                v-model="client.giftIdeas"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Last 4 SSN"
                type="text"
                :disabled="sending"
                v-validate="'numeric'"
                v-model="client.ssn"
                maxlength="4"
                @change="updateCode()"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-menu
                :close-on-content-click="false"
                v-model="menu1"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="client.intakeDate"
                  label="Intake Date"
                  prepend-icon="event"
                  readonly
                  :disabled="sending"
                ></v-text-field>
                <v-date-picker v-model="client.intakeDate" @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm6>
              <v-textarea
                label="Comments"
                type="text"
                :disabled="sending"
                v-model="client.comments"
              />
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 class="text-xs-right primary flex-button-container">
              <v-btn flat type="submit" color="white" :disabled="sending">Check for Duplicates</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="error">
          <v-btn icon @click="dialog = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-toolbar-title>Potential Duplicates Found</v-toolbar-title>
          <div class="flex-grow-1"></div>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="duplicates" item-key="id">
            <template slot="items" slot-scope="props">
              <td>{{props.item.code}}</td>
              <td>{{ props.item.firstName }}</td>
              <td>{{ props.item.lastName }}</td>
              <td>{{ props.item.householdName }}</td>
              <td>{{ props.item.genderName }}</td>
              <td>{{ props.item.dob}}</td>
              <td>{{ props.item.ssn }}</td>
              <td>{{ props.item.intakeDate}}</td>
              <td>{{ props.item.comments }}</td>
              <!-- <td class="center">
                <v-btn flat color="error">Dispute</v-btn>
              </td>-->
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-layout>
            <v-flex xs12 class="text-xs-right flex-button-container">
              <v-btn flat @click="dialog = false">Cancel</v-btn>
              <v-btn
                flat
                type="submit"
                color="primary"
                :disabled="sending"
                @click="save()"
              >Save Anyway</v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="sheet" inset persistent>
      <v-sheet class="center" height="200px">
        <div style="padding-top:20px">
          <h3 class="headline">No duplicates found!</h3>
        </div>
        <v-btn class="mt-6" flat @click="sheet = !sheet">Cancel</v-btn>
        <v-btn class="mt-6" flat color="primary" @click="save()">Save</v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>

<style scoped>
</style>

<script>
import Vue from "vue";

export default {
  name: "user",
  async beforeRouteEnter(to, from, next) {
    try {
      let client = {
        data: {
          code: "",
          householdId: to.params.householdId,
          intakeDate: new Date().toISOString().substr(0, 10)
        }
      };
      if (to.params.clientId != 0) {
        client = await Vue.$clientService.findOne(to.params.clientId);
        client.data.dob = client.data.dob
          ? new Date(client.data.dob).toISOString().substr(0, 10)
          : null;
        client.data.intakeDate = client.data.intakeDate
          ? new Date(client.data.intakeDate).toISOString().substr(0, 10)
          : null;
      }
      let genders = await Vue.$genderService.find();

      return next(vm => {
        vm.client = client.data ? client.data : {};
        vm.genders = genders.data;
        vm.showHouseholdNav = from.name == "household" ? true : false;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      next(false);
    }
  },
  data() {
    return {
      client: {},
      dialog: false,
      duplicates: [],
      genders: [],
      headers: [
        { text: "Name SSN Code", value: "code" },
        { text: "First Name", value: "fullName" },
        { text: "Last Name", value: "lastName" },
        { text: "Household", value: "householdName" },
        { text: "Gender", value: "genderName" },
        { text: "DOB", value: "dob" },
        { text: "SSN", value: "ssn" },
        { text: "Intake Date", value: "intakeDate" },
        { text: "Comments", value: "comments" }
        // { text: "Actions", sortable: false, value: "id" }
      ],
      menu1: false,
      menu2: false,
      sending: false,
      sheet: false,
      showHouseholdNav: false
    };
  },
  methods: {
    async duplicateCheck() {
      try {
        let result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.send(true);

      try {
        let res = await Vue.$clientService.find({
          params: {
            code: this.client.code,
            id: this.client.id
          }
        });

        this.duplicates = Vue.$clientService.map(res);

        this.send(false);

        if (this.duplicates.length > 0) {
          this.dialog = true;
        } else {
          this.sheet = true;
        }
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    async save() {
      this.send(true);

      try {
        let res = await Vue.$clientService.save(this.client);

        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Client Saved Successfully!");
        this.sheet = false;
        this.dialog = false;
        this.$router.go(-1);
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    },
    updateCode() {
      let ssnTemp = "xxxx";
      let lastTemp = this.client.lastName
        ? this.client.lastName.substr(0, 3)
        : "";

      if (this.client.ssn && this.client.ssn.length === 4) {
        ssnTemp = this.client.ssn;
      }

      this.client.code = `${lastTemp}${ssnTemp}`;
    }
  }
};
</script>