<template>
  <v-card>
    <v-card-title>
      <h3 class="headline">Users</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="users" :search="search" item-key="id">
        <template slot="items" slot-scope="props">
          <td>
            <v-btn
              flat
              color="error"
              class="red lighten-5 my-grid-button"
              @click="promptDelete(props.item)"
              icon
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              flat
              color="primary"
              class="blue lighten-5 my-grid-button"
              :to="{ name: 'user', params: {userId: props.item.id}}"
              icon
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
          <td>{{ props.item.firstName }}</td>
          <td>{{ props.item.lastName }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.agencyName }}</td>
          <td class="center">
            <v-icon v-if="props.item.isAdmin" color="accent">done</v-icon>
          </td>
          <td class="center">
            <v-btn
              flat
              color="accent"
              class="green lighten-5"
              @click="approve(props.item)"
              v-if="!props.item.approved"
            >Approve</v-btn>
            <v-icon v-if="props.item.approved" color="accent">done</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Delete {{deleteUser.fullName}}</v-card-title>

        <v-card-text>Are you sure you want to delete {{deleteUser.fullName}}?</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" flat text @click="doDelete(deleteUser)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style>
</style>

<script>
import Vue from "vue";

export default {
  name: "users",
  async beforeRouteEnter(to, from, next) {
    try {
      let users = await Vue.$userService.find();

      users = Vue.$userService.map(users);

      return next(vm => {
        vm.users = users;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  data() {
    return {
      deleteDialog: false,
      deleteUser: {},
      users: [],
      headers: [
        { text: "Actions", sortable: false, value: "id" },
        { text: "First Name", value: "fullName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Agency", value: "agencyName" },
        { text: "Admin", value: "isAdmin" },
        { text: "Approved", value: "approved" }
      ],
      search: "",
      sending: false
    };
  },
  methods: {
    async approve(user) {
      this.send(true);
      Vue.$eventBus.$emit("showSending", this.sending);
      try {
        user.approved = true;
        await Vue.$userService.save(user);
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "User Approved!");
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    async doDelete(user) {
      this.send(true);

      try {
        let res = await Vue.$userService.delete(user);
        this.send(false);
        this.deleteDialog = false;
        Vue.$eventBus.$emit("notifyUser", "User Deleted Successfully!");
        this.$router.go();
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    promptDelete(user) {
      this.deleteDialog = true;
      this.deleteUser = user;
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    }
  }
};
</script>