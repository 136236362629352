var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("h3", { staticClass: "headline" }, [_vm._v("Users")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.users,
              search: _vm.search,
              "item-key": "id"
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "red lighten-5 my-grid-button",
                            attrs: { flat: "", color: "error", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.promptDelete(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("delete")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "blue lighten-5 my-grid-button",
                            attrs: {
                              flat: "",
                              color: "primary",
                              to: {
                                name: "user",
                                params: { userId: props.item.id }
                              },
                              icon: ""
                            }
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.firstName))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.lastName))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.email))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.agencyName))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "center" },
                      [
                        props.item.isAdmin
                          ? _c("v-icon", { attrs: { color: "accent" } }, [
                              _vm._v("done")
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "center" },
                      [
                        !props.item.approved
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "green lighten-5",
                                attrs: { flat: "", color: "accent" },
                                on: {
                                  click: function($event) {
                                    return _vm.approve(props.item)
                                  }
                                }
                              },
                              [_vm._v("Approve")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        props.item.approved
                          ? _c("v-icon", { attrs: { color: "accent" } }, [
                              _vm._v("done")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey lighten-2",
                  attrs: { "primary-title": "" }
                },
                [_vm._v("Delete " + _vm._s(_vm.deleteUser.fullName))]
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "Are you sure you want to delete " +
                    _vm._s(_vm.deleteUser.fullName) +
                    "?"
                )
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.doDelete(_vm.deleteUser)
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }