export default {
  install: (Vue) => {
    //private
    const route = '/api/gender/';

    //public
    Vue.$genderService = {
      async find(params) {
        const genders = JSON.parse(sessionStorage.getItem('genders'));

        if (genders) {
          return genders;
        }

        const res = await Vue.axios.get(route, params);

        window.sessionStorage.setItem(
          'genders',
          JSON.stringify(res)
        );

        return res;
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      }
    };
  }
};