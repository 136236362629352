export default {
  install: (Vue) => {
    const route = '/api/admin/user/';
    const put = (id, user, params) => Vue.axios.put(`${route}${id}`, user, params);
    const post = (user, params) => Vue.axios.post(route, user, params);

    Vue.$userService = {
      map(users) {
        users = users && users.data ? users.data : users;

        if (users) {
          users.map(user => {
            user.agencyName = user.agency ? user.agency.name : '';
            user.fullName = `${user.firstName} ${user.lastName}`;

            return user;
          });
        }
        return users;
      },
      find(params) {
        return Vue.axios.get(route, params);
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      },

      save(user, params) {
        if (user.id) {
          let me = Vue.$accountService.current();
          if (user.id === me.id) {
            window.sessionStorage.setItem('user', JSON.stringify(user));
            Vue.$eventBus.$emit('updateUser');
          }

          return put(user.id, user, params);
        }

        return post(user, params);
      },

      delete(user) {
        return Vue.axios.delete(`${route}${user.id}`);
      },
    };
  }
};