var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _vm.showHouseholdNav
            ? _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    flat: "",
                    icon: "",
                    to: {
                      name: "household",
                      params: { householdId: _vm.client.householdId }
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("arrow_left")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.showHouseholdNav
            ? _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    flat: "",
                    icon: "",
                    to: { name: "clients" }
                  }
                },
                [
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("arrow_left")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "title font-weight-light" }, [
            _vm._v("\n      Back to\n      "),
            !_vm.showHouseholdNav ? _c("span", [_vm._v("Clients")]) : _vm._e(),
            _vm._v(" "),
            _vm.showHouseholdNav ? _c("span", [_vm._v("Household")]) : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "h3",
            {
              staticClass: "headline",
              staticStyle: { "text-align": "center" }
            },
            [_vm._v(_vm._s(_vm._f("personName")(_vm.client)))]
          ),
          _vm._v(" "),
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.duplicateCheck($event)
                }
              }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              label: "Name SSN Code",
                              type: "text",
                              disabled: _vm.sending || true,
                              "error-messages": _vm.errors.collect(
                                "Name SSN Code"
                              ),
                              "data-vv-name": "Name SSN Code",
                              required: "",
                              maxlength: "7"
                            },
                            model: {
                              value: _vm.client.code,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "code", $$v)
                              },
                              expression: "client.code"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              label: "First Name",
                              type: "text",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect(
                                "First Name"
                              ),
                              "data-vv-name": "First Name",
                              required: ""
                            },
                            model: {
                              value: _vm.client.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "firstName", $$v)
                              },
                              expression: "client.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              label: "Last Name",
                              type: "text",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect("Last Name"),
                              "data-vv-name": "Last Name",
                              required: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateCode()
                              }
                            },
                            model: {
                              value: _vm.client.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "lastName", $$v)
                              },
                              expression: "client.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-autocomplete", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              items: _vm.genders,
                              "item-text": "name",
                              label: "Gender",
                              "persistent-hint": "",
                              "return-object": "",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect("Gender"),
                              "data-vv-name": "Gender",
                              required: ""
                            },
                            model: {
                              value: _vm.client.gender,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "gender", $$v)
                              },
                              expression: "client.gender"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px"
                              },
                              model: {
                                value: _vm.menu2,
                                callback: function($$v) {
                                  _vm.menu2 = $$v
                                },
                                expression: "menu2"
                              }
                            },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                attrs: {
                                  slot: "activator",
                                  label: "Date of Birth",
                                  "prepend-icon": "event",
                                  readonly: "",
                                  disabled: _vm.sending,
                                  "error-messages": _vm.errors.collect(
                                    "Date of Birth"
                                  ),
                                  "data-vv-name": "Date of Birth",
                                  required: ""
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.client.dob,
                                  callback: function($$v) {
                                    _vm.$set(_vm.client, "dob", $$v)
                                  },
                                  expression: "client.dob"
                                }
                              }),
                              _vm._v(" "),
                              _c("v-date-picker", {
                                on: {
                                  input: function($event) {
                                    _vm.menu2 = false
                                  }
                                },
                                model: {
                                  value: _vm.client.dob,
                                  callback: function($$v) {
                                    _vm.$set(_vm.client, "dob", $$v)
                                  },
                                  expression: "client.dob"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Clothing Size",
                              type: "text",
                              disabled: _vm.sending
                            },
                            model: {
                              value: _vm.client.clothingSize,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "clothingSize", $$v)
                              },
                              expression: "client.clothingSize"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Gift Ideas",
                              type: "text",
                              disabled: _vm.sending
                            },
                            model: {
                              value: _vm.client.giftIdeas,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "giftIdeas", $$v)
                              },
                              expression: "client.giftIdeas"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "numeric",
                                expression: "'numeric'"
                              }
                            ],
                            attrs: {
                              label: "Last 4 SSN",
                              type: "text",
                              disabled: _vm.sending,
                              maxlength: "4"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateCode()
                              }
                            },
                            model: {
                              value: _vm.client.ssn,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "ssn", $$v)
                              },
                              expression: "client.ssn"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px"
                              },
                              model: {
                                value: _vm.menu1,
                                callback: function($$v) {
                                  _vm.menu1 = $$v
                                },
                                expression: "menu1"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  slot: "activator",
                                  label: "Intake Date",
                                  "prepend-icon": "event",
                                  readonly: "",
                                  disabled: _vm.sending
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.client.intakeDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.client, "intakeDate", $$v)
                                  },
                                  expression: "client.intakeDate"
                                }
                              }),
                              _vm._v(" "),
                              _c("v-date-picker", {
                                on: {
                                  input: function($event) {
                                    _vm.menu1 = false
                                  }
                                },
                                model: {
                                  value: _vm.client.intakeDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.client, "intakeDate", $$v)
                                  },
                                  expression: "client.intakeDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Comments",
                              type: "text",
                              disabled: _vm.sending
                            },
                            model: {
                              value: _vm.client.comments,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "comments", $$v)
                              },
                              expression: "client.comments"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass:
                            "text-xs-right primary flex-button-container",
                          attrs: { xs12: "" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                type: "submit",
                                color: "white",
                                disabled: _vm.sending
                              }
                            },
                            [_vm._v("Check for Duplicates")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "error" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("clear")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-toolbar-title", [_vm._v("Potential Duplicates Found")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-grow-1" })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.duplicates,
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [_vm._v(_vm._s(props.item.code))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.firstName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.lastName))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.householdName))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.genderName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.dob))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.ssn))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.intakeDate))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.comments))])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "text-xs-right flex-button-container",
                          attrs: { xs12: "" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                type: "submit",
                                color: "primary",
                                disabled: _vm.sending
                              },
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save Anyway")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-bottom-sheet",
        {
          attrs: { inset: "", persistent: "" },
          model: {
            value: _vm.sheet,
            callback: function($$v) {
              _vm.sheet = $$v
            },
            expression: "sheet"
          }
        },
        [
          _c(
            "v-sheet",
            { staticClass: "center", attrs: { height: "200px" } },
            [
              _c("div", { staticStyle: { "padding-top": "20px" } }, [
                _c("h3", { staticClass: "headline" }, [
                  _vm._v("No duplicates found!")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-6",
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      _vm.sheet = !_vm.sheet
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-6",
                  attrs: { flat: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }