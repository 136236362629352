<template>
  <v-card>
    <v-card-title>
      <v-btn small flat icon :to="{ name: 'users'}">
        <v-icon medium>arrow_left</v-icon>
      </v-btn>
      <span class="title font-weight-light">Back to Users</span>
    </v-card-title>
    <v-card-text>
      <h3 class="headline" style="text-align: center">{{user | personName}}</h3>

      <v-form v-on:submit.prevent="save">
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm6 md3>
              <v-text-field
                label="First Name"
                type="text"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('First Name')"
                data-vv-name="First Name"
                required
                v-model="user.firstName"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Last Name"
                type="text"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('Last Name')"
                data-vv-name="Last Name"
                required
                v-model="user.lastName"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Email"
                type="email"
                :disabled="sending"
                v-validate="'required|email'"
                :error-messages="errors.collect('Email')"
                data-vv-name="Email"
                required
                v-model="user.email"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-autocomplete
                v-model="user.agency"
                :items="agencies"
                item-text="name"
                label="Agency"
                persistent-hint
                return-object
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('Agency')"
                data-vv-name="Agency"
                required
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-checkbox color="primary" label="Admin" v-model="user.isAdmin" :disabled="sending"></v-checkbox>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-checkbox
                color="primary"
                label="Approved"
                v-model="user.approved"
                :disabled="sending"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-checkbox
                color="primary"
                label="Active"
                v-model="user.active"
                :disabled="sending"
              ></v-checkbox>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 class="text-xs-right primary flex-button-container">
              <v-btn flat type="submit" color="white" :disabled="sending">Save</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped>
</style>

<script>
import Vue from "vue";

export default {
  name: "user",
  async beforeRouteEnter(to, from, next) {
    try {
      let [agencies, user] = await Vue.axios.all([
        Vue.$agencyService.find(),
        Vue.$userService.findOne(to.params.userId)
      ]);

      return next(vm => {
        vm.agencies = agencies.data;
        vm.user = user.data;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  data() {
    return {
      agencies: [],
      user: {},
      sending: false
    };
  },
  methods: {
    async save() {
      try {
        let result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.send(true);
      if (this.user.agency) this.user.agencyId = this.user.agency.id;

      try {
        let res = await Vue.$userService.save(this.user);
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "User Saved Successfully!");
        this.$router.go(-1);
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    }
  }
};
</script>