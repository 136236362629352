<template>
  <v-card>
    <v-card-title>
      <h3 class="headline">Households</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="searchText" v-debounce:500ms="searchHouseholds" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-btn color="accent" :to="{ name: 'household', params: {householdId: 0}}">Add</v-btn>
      <v-data-table 
        :headers="headers" 
        :items="households" 
        :total-items="householdsCount"
        :loading="loading"
        :pagination.sync="pagination"
        :rows-per-page-items="rowsPerPageItems"
        @update:pagination="updateResults"
        item-key="id">
        <template slot="items" slot-scope="props">
          <td class="my-grid-group">
            <v-btn
              flat
              color="error"
              class="red lighten-5 my-grid-button"
              @click="promptDelete(props.item)"
              icon
              v-if="user.isAdmin"
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              flat
              color="primary"
              class="blue lighten-5 my-grid-button"
              :to="{ name: 'household', params: {householdId: props.item.id}}"
              icon
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
          <td>{{ props.item.firstName }}</td>
          <td>{{ props.item.lastName }}</td>
          <td>{{ props.item.phoneNumber | phoneNumber }}</td>
          <td>{{ props.item.addressLine1 }}</td>
          <td>{{ props.item.state.name }}</td>
          <td>{{ props.item.city.name }}</td>
          <td>{{ props.item.zip }}</td>
          <td class="center">
            <v-icon v-if="props.item.holidayBasket" color="accent">done</v-icon>
          </td>
          <td>{{ props.item.comments }}</td>
          <td>{{props.item.agency.name}}</td>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >Delete {{deleteHousehold.fullName}}</v-card-title>

        <v-card-text>
          Are you sure you want to delete {{deleteHousehold.fullName}} and
          <b>all associated clients</b>?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" flat text @click="doDelete(deleteHousehold)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style>
</style>

<script>
import Vue from "vue";

export default {
  name: "households",
  async beforeRouteEnter(to, from, next) {
    try {
      let user = await Vue.$accountService.current();
      return next(vm => {
        vm.user = user;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  data() {
    return {
      expanded: [],
      deleteDialog: false,
      deleteHousehold: {},
      households: [],
      householdsCount: null,
      loading: false,
      rowsPerPageItems: [5, 10, 25, 50],
      searchText: '',
      pagination: {},
      headers: [
        { text: "Actions", sortable: false, value: "id" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Phone", value: "phoneNumber" },
        { text: "Address 1", value: "addressLine1" },
        { text: "State", value: "state.name" },
        { text: "City", value: "city.name" },
        { text: "Zip", value: "zip" },
        { text: "Food/Holiday Basket", value: "holidayBasket" },
        { text: "Comments", value: "comments" },
        { text: "Agency", value: "agency.name" }
      ],
      search: "",
      sending: false,
      user: {}
    };
  },
  methods: {
    async doDelete(household) {
      this.send(true);

      try {
        let res = await Vue.$householdService.delete(household);
        this.send(false);
        this.deleteDialog = false;
        Vue.$eventBus.$emit("notifyUser", "Household Deleted Successfully!");
        this.$router.go();
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    promptDelete(household) {
      this.deleteDialog = true;
      this.deleteHousehold = household;
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    },
    async searchHouseholds(searchText) {
      this.pagination.page = 1;
      await this.updateResults(this.pagination, searchText)
    },
    async updateResults(paginationInfo, searchText) {
      this.loading = true;
      let householdsInfo = await Vue.$householdService.find({
        params: {
          limit: paginationInfo.rowsPerPage,
          offset: (paginationInfo.page - 1) * paginationInfo.rowsPerPage,
          orderBy: paginationInfo.sortBy,
          descending: paginationInfo.descending,
          text: searchText || this.searchText
        }
      });
      this.households = householdsInfo.data.rows;
      this.householdsCount = householdsInfo.data.count;
      this.loading = false;
    }
  }
};
</script>