<template>
  <div id="app">
    <v-app id="inspire">
      <v-toolbar fixed app clipped-left color="accent">
        <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
        <v-toolbar-title>WNY Toys</v-toolbar-title>

        <v-spacer></v-spacer>
        <div class="sponsor-text">
          Software Sponsored by
        </div>
        <img class="sponsor-image" src="../shared/images/wc-logo.png" />
        <v-spacer></v-spacer>
        <v-avatar color="error">
          <span class="white--text headline" v-if="user">{{formatAvatar(user)}}</span>
        </v-avatar>
      </v-toolbar>

      <v-progress-linear v-if="sending" height="5" class="my-progress" :indeterminate="true"></v-progress-linear>

      <v-navigation-drawer fixed clipped v-model="drawer" app style="display:flex; flex-direction:column;">
        <v-list dense>
          <v-list-tile :to="{ name: 'households'}">
            <v-list-tile-action>
              <v-icon>home</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Households</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile :to="{ name: 'clients'}">
            <v-list-tile-action>
              <v-icon>person</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Clients</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <!-- <v-list-tile :to="{ name: 'households'}">
            <v-list-tile-action>
              <v-icon>gavel</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Disputes</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>-->

          <v-divider v-if="user.isAdmin"></v-divider>
          <v-list-tile v-if="user.isAdmin">Admin</v-list-tile>

          <v-list-tile :to="{ name: 'users'}" v-if="user.isAdmin">
            <v-list-tile-action>
              <v-icon>face</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Users</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile :to="{ name: 'agencies'}" v-if="user.isAdmin">
            <v-list-tile-action>
              <v-icon>domain</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Agencies</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-divider></v-divider>

          <v-list-tile :href="'https://account.allco.app/myAccount/preferences'">
            <v-list-tile-action>
              <v-icon>settings</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Account Management</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <!-- <v-list-tile v-on:click="mailTo()">
            <v-list-tile-action>
              <v-icon>help</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Support</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>-->

          <v-list-tile v-on:click="logout()">
            <v-list-tile-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Log Out</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-spacer></v-spacer>
        <v-list style="margin-bottom: 45px;">
          <v-list-tile :href="'https://organization.allco.app'">
            <v-list-tile-action>
              <img class="allco-icon" src="../shared/images/allco-icon.png" />
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Go To Your Allco Profile</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
      <v-content>
        <v-container fluid fill-height>
          <v-layout justify-center align-top>
            <v-flex>
              <router-view></router-view>
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
      <v-footer app style="height:80px">
        <v-layout row wrap text-xs-center>
          <v-flex xs12>
            &copy;{{today.getFullYear()}} —
            <strong>The Western New York Holiday Partnership</strong>
          </v-flex>
          <v-flex xs12>
            Created by <a href="https://rprt.dev">rprt</a>
          </v-flex>
          <v-flex xs12>
            <img class="rprt-logo" src="../shared/images/rprt-logo.webp">
          </v-flex>
        </v-layout>
      </v-footer>
    </v-app>
    <v-snackbar v-model="snackbar.show" bottom>{{ snackbar.message }}</v-snackbar>
  </div>
</template>

<style>
.my-grid-group {
  padding: 5px !important;
}
.my-grid-button {
  margin: 0px !important;
}
.my-progress {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 65px;
}
.center {
  text-align: center;
}
.rprt-logo {
  width: 50px;
}
.sponsor-text {
  font-size: 22px;
  width: 160px;
  line-height: 26px;
  color: white;
  margin-right: -20px;
}
.sponsor-image {
  width: 260px;
}
.allco-icon {
  width: 40px;
  margin-left: -9px;
}
</style>

 <script>
import Vue from "vue";

export default {
  name: "app",
  data: () => {
    return {
      drawer: true,
      menuVisible: false,
      sending: false,
      sendingCount: 0,
      snackbar: {
        position: "center",
        duration: 4000,
        message: null,
        show: false
      },
      today: new Date(),
      user: null
    };
  },
  beforeMount() {
    this.user = Vue.$accountService.current();
  },
  created() {
    Vue.$eventBus.$on("notifyUser", this.notifyUser);
    Vue.$eventBus.$on("showSending", this.showSending);
    Vue.$eventBus.$on("updateUser", this.updateUser);
  },
  destroyed() {
    Vue.$eventBus.$off("notifyUser", this.notifyUser);
    Vue.$eventBus.$off("showSending", this.showSending);
    Vue.$eventBus.$off("updateUser", this.updateUser);
  },
  methods: {
    formatAvatar(user) {
      if (!user) {
        return "";
      }

      const firstName = user.firstName;
      const lastName = user.lastName;
      const firstInitial = firstName ? firstName.charAt(0) : "";
      const lastInitial = lastName ? lastName.charAt(0) : "";

      return `${firstInitial}${lastInitial}`;
    },
    logout() {
      Vue.$accountService.logout(this);
    },
    mailTo() {
      window.open("mailto:somesupport@email.com");
    },
    notifyUser: function(message) {
      this.snackbar.message = message;
      this.snackbar.show = true;
    },
    showSending(show) {
      this.sending = show;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    updateUser() {
      this.user = Vue.$accountService.current();
    }
  }
};
</script>