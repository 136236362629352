export default (person, plural) => {
  if (person) {
    let firstName = person.firstName ? person.firstName : '';
    let lastName = person.lastName ? person.lastName : '';
    let fullName = '';

    lastName = firstName !== '' && lastName !== '' ? ` ${lastName}` : lastName;
    fullName = `${firstName}${lastName}`;

    if (plural) {
      fullName = fullName === '' ? '' : `${fullName}'s`;
    }

    return fullName;
  }
};