<template>
  <div>
    <v-form v-on:submit.prevent="submitPersonal">
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="First Name"
              type="text"
              :disabled="sending"
              v-validate="'required'"
              :error-messages="errors.collect('First Name')"
              data-vv-name="First Name"
              required
              v-model="user.firstName"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md3>
            <v-text-field
              label="Last Name"
              type="text"
              :disabled="sending"
              v-validate="'required'"
              :error-messages="errors.collect('Last Name')"
              data-vv-name="Last Name"
              required
              v-model="user.lastName"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6 md3>
            <v-text-field
              prepend-icon="mail"
              label="Email"
              type="email"
              :disabled="sending || true"
              v-validate="'required|email'"
              :error-messages="errors.collect('Email')"
              data-vv-name="Email"
              required
              v-model="user.email"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6 md3>
            <v-autocomplete
              v-model="user.agency"
              :items="agencies"
              item-text="name"
              label="Agency"
              persistent-hint
              return-object
              :disabled="sending || true"
              v-validate="'required'"
              :error-messages="errors.collect('Agency')"
              data-vv-name="Agency"
              required
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 class="text-xs-right primary">
            <v-btn flat type="submit" color="white" :disabled="sending">Save</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "DetailSection",
  props: ["agencies", "user"],
  data() {
    return {
      sending: false
    };
  },
  methods: {
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    },
    async submitPersonal() {
      try {
        let result = await this.$validator.validate();
        if (!result) {
          return false;
        }
      } catch (err) {
        return;
      }

      this.send(true);

      try {
        let res = await Vue.$accountService.save(this.user);
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Account Updated Successfully!");
      } catch (err) {
        this.send(false);
        var message = "";

        if (err.response.status === 400) {
          if (err.response.data.email) {
            message = "This email is already in use";
          } else {
            message = "A validation error has occured";
          }
        } else {
          message = "An unknown error has occured";
        }

        Vue.$eventBus.$emit("notifyUser", message);
      }
    }
  }
};
</script>
