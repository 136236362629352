var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-btn",
            { attrs: { small: "", flat: "", icon: "", to: { name: "users" } } },
            [_c("v-icon", { attrs: { medium: "" } }, [_vm._v("arrow_left")])],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "title font-weight-light" }, [
            _vm._v("Back to Users")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "h3",
            {
              staticClass: "headline",
              staticStyle: { "text-align": "center" }
            },
            [_vm._v(_vm._s(_vm._f("personName")(_vm.user)))]
          ),
          _vm._v(" "),
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              label: "First Name",
                              type: "text",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect(
                                "First Name"
                              ),
                              "data-vv-name": "First Name",
                              required: ""
                            },
                            model: {
                              value: _vm.user.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "firstName", $$v)
                              },
                              expression: "user.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              label: "Last Name",
                              type: "text",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect("Last Name"),
                              "data-vv-name": "Last Name",
                              required: ""
                            },
                            model: {
                              value: _vm.user.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "lastName", $$v)
                              },
                              expression: "user.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'"
                              }
                            ],
                            attrs: {
                              label: "Email",
                              type: "email",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect("Email"),
                              "data-vv-name": "Email",
                              required: ""
                            },
                            model: {
                              value: _vm.user.email,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "email", $$v)
                              },
                              expression: "user.email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-autocomplete", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              items: _vm.agencies,
                              "item-text": "name",
                              label: "Agency",
                              "persistent-hint": "",
                              "return-object": "",
                              disabled: _vm.sending,
                              "error-messages": _vm.errors.collect("Agency"),
                              "data-vv-name": "Agency",
                              required: ""
                            },
                            model: {
                              value: _vm.user.agency,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "agency", $$v)
                              },
                              expression: "user.agency"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Admin",
                              disabled: _vm.sending
                            },
                            model: {
                              value: _vm.user.isAdmin,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "isAdmin", $$v)
                              },
                              expression: "user.isAdmin"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Approved",
                              disabled: _vm.sending
                            },
                            model: {
                              value: _vm.user.approved,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "approved", $$v)
                              },
                              expression: "user.approved"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md3: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Active",
                              disabled: _vm.sending
                            },
                            model: {
                              value: _vm.user.active,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "active", $$v)
                              },
                              expression: "user.active"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass:
                            "text-xs-right primary flex-button-container",
                          attrs: { xs12: "" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                type: "submit",
                                color: "white",
                                disabled: _vm.sending
                              }
                            },
                            [_vm._v("Save")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }