export default {
  install: (Vue) => {
    const route = '/api/household/';
    const put = (id, household, params) => Vue.axios.put(`${route}${id}`, household, params);
    const post = (household, params) => Vue.axios.post(route, household, params);

    Vue.$householdService = {
      map(households) {
        households = households && households.data ? households.data : households;

        if (households) {
          households.map(household => {
            household.fullName = `${household.firstName} ${household.lastName}`;
            household.cityName = household.city ? household.city.name : '';
            household.stateName = household.state ? household.state.name : '';
            household.phoneNumber = `(${household.phoneNumber.substr(0, 3)}) ${household.phoneNumber.substr(3, 3)} - ${household.phoneNumber.substr(6)}`;
            household.agencyName = household.agency ? household.agency.name : '';

            return household;
          });
        }
        return households;
      },
      find(params) {
        return Vue.axios.get(route, params);
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      },

      save(household, params) {
        if (household.state) household.stateId = household.state.id;
        if (household.city) household.cityId = household.city.id;
        if (household.agency) household.agencyId = household.agency.id;

        if (household.id) {
          return put(household.id, household, params);
        }

        return post(household, params);
      },

      delete(household) {
        return Vue.axios.delete(`${route}${household.id}`);
      },
    };
  }
};