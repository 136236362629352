import Vue from 'vue';
import Router from 'vue-router';

import Account from '../components/account/Account.vue';
import Agency from '../components/agency/Agency.vue';
import Agencies from '../components/agency/Agencies.vue';
import Client from '../components/client/Client.vue';
import Clients from '../components/client/Clients.vue';
import Household from '../components/household/Household.vue';
import Households from '../components/household/Households.vue';
import NotFound from '../components/NotFound.vue';
import User from '../components/user/User.vue';
import Users from '../components/user/Users.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/app',
  routes: [{
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        admin: false
      }
    }, {
      path: '/households',
      component: {
        template: '<router-view></router-view>'
      },
      children: [{
          path: '',
          name: 'households',
          component: Households,
          meta: {
            admin: false
          }
        },
        {
          path: ':householdId',
          name: 'household',
          component: Household,
          meta: {
            admin: false
          }
        }
      ]
    }, , {
      path: '/clients',
      component: {
        template: '<router-view></router-view>'
      },
      children: [{
          path: '',
          name: 'clients',
          component: Clients,
          meta: {
            admin: false
          }
        },
        {
          path: ':householdId/client/:clientId',
          name: 'client',
          component: Client,
          meta: {
            admin: false
          }
        }
      ]
    }, {
      path: '/users',
      component: {
        template: '<router-view></router-view>'
      },
      children: [{
          path: '',
          name: 'users',
          component: Users,
          meta: {
            admin: true
          }
        },
        {
          path: ':userId',
          name: 'user',
          component: User,
          meta: {
            admin: true
          }
        }
      ]
    }, {
      path: '/agencies',
      component: {
        template: '<router-view></router-view>'
      },
      children: [{
          path: '',
          name: 'agencies',
          component: Agencies,
          meta: {
            admin: true
          }
        },
        {
          path: ':agencyId',
          name: 'agency',
          component: Agency,
          meta: {
            admin: true
          }
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: NotFound,
      meta: {
        admin: false
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const user = Vue.$accountService.current();
  if (!user.isAdmin && to.meta.admin) {
    next('households');
  } else {
    next();
  }
});

export default router;