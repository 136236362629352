<template>
  <v-card>
    <v-card-title>
      <v-btn small flat icon :to="{ name: 'households'}">
        <v-icon medium>arrow_left</v-icon>
      </v-btn>
      <span class="title font-weight-light">Back to Households</span>
    </v-card-title>
    <v-card-text>
      <v-tabs fixed-tabs>
        <v-tab>{{household | personName(true)}} Household</v-tab>
        <v-tab v-if="household.id">Clients</v-tab>

        <v-tab-item>
          <v-form>
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 sm6 md3>
                  <v-text-field
                    label="First Name"
                    type="text"
                    :disabled="sending"
                    v-validate="'required'"
                    :error-messages="errors.collect('First Name')"
                    data-vv-name="First Name"
                    required
                    v-model="household.firstName"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-text-field
                    label="Last Name"
                    type="text"
                    :disabled="sending"
                    v-validate="'required'"
                    :error-messages="errors.collect('Last Name')"
                    data-vv-name="Last Name"
                    required
                    v-model="household.lastName"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-text-field
                    label="Phone"
                    type="tel"
                    :disabled="sending"
                    mask="phone"
                    v-validate="'required'"
                    :error-messages="errors.collect('Phone')"
                    data-vv-name="Phone"
                    required
                    v-model="household.phoneNumber"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-text-field
                    label="Address 1"
                    type="text"
                    :disabled="sending"
                    v-validate="'required'"
                    :error-messages="errors.collect('Address 1')"
                    data-vv-name="Address 1"
                    required
                    v-model="household.addressLine1"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-text-field
                    label="Address 2"
                    type="text"
                    :disabled="sending"
                    v-model="household.addressLine2"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-autocomplete
                    v-model="household.state"
                    :items="states"
                    item-text="name"
                    label="State"
                    persistent-hint
                    return-object
                    @change="selectState()"
                    v-validate="'required'"
                    :error-messages="errors.collect('State')"
                    data-vv-name="State"
                    required
                    :disabled="sending"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-autocomplete
                    v-model="household.city"
                    :items="cities"
                    item-text="name"
                    label="City"
                    persistent-hint
                    return-object
                    v-validate="'required'"
                    :error-messages="errors.collect('City')"
                    data-vv-name="City"
                    required
                    :disabled="sending || !household.state"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-text-field
                    label="Zip"
                    type="text"
                    :disabled="sending"
                    v-model="household.zip"
                    maxlength="5"
                    v-validate="'required'"
                    :error-messages="errors.collect('Zip')"
                    data-vv-name="Zip"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="user.isAdmin" xs12 sm6 md3>
                  <v-autocomplete
                    v-model="household.agency"
                    :items="agencies"
                    item-text="name"
                    label="Agency"
                    persistent-hint
                    return-object
                    v-validate="'required'"
                    :error-messages="errors.collect('Agency')"
                    data-vv-name="Agency"
                    required
                    :disabled="sending"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md3>
                  <v-checkbox
                    color="primary"
                    label="Food/Holiday Basket"
                    v-model="household.holidayBasket"
                    :disabled="sending"
                  ></v-checkbox>
                </v-flex>

                <v-flex xs12 sm6>
                  <v-textarea
                    label="Comments"
                    type="text"
                    :disabled="sending"
                    v-model="household.comments"
                  />
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs12 class="text-xs-right primary flex-button-container">
                  <v-btn flat type="submit" color="white" :disabled="sending" @click.prevent="save()">Save</v-btn>
                  <v-btn
                    flat
                    type="submit"
                    color="white"
                    :disabled="sending"
                    @click.prevent="save(true)"
                  >Save &amp; Back</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item v-if="household.id">
          <v-layout>
            <v-flex xs12 sm6>
              <v-btn
                color="accent"
                :to="{ name: 'client', params: {householdId: household.id, clientId: 0}}"
              >Add</v-btn>
            </v-flex>

            <v-flex xs12 sm6>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-data-table :headers="headers" :items="clients" :search="search" item-key="id">
            <template slot="items" slot-scope="props">
              <td class="my-grid-group">
                <v-btn
                  flat
                  color="error"
                  class="red lighten-5 my-grid-button"
                  @click="promptDelete(props.item)"
                  icon
                  v-if="user.isAdmin"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-btn
                  flat
                  color="primary"
                  class="blue lighten-5 my-grid-button"
                  :to="{ name: 'client', params: {householdId: household.id, clientId: props.item.id}}"
                  icon
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
              <td>{{props.item.code}}</td>
              <td>{{ props.item.firstName }}</td>
              <td>{{ props.item.lastName }}</td>
              <td>{{ props.item.genderName }}</td>
              <td>{{ props.item.dob }}</td>
              <td>{{ props.item.ssn }}</td>
              <td>{{ props.item.intakeDate }}</td>
              <td>{{ props.item.comments }}</td>
              <td>{{props.item.agencyName}}</td>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Delete {{deleteClient.fullName}}</v-card-title>

        <v-card-text>Are you sure you want to delete {{deleteClient.fullName}}?</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" flat text @click="doDelete(deleteClient)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
</style>

<script>
import Vue from "vue";
import moment from "moment";

export default {
  name: "user",
  async beforeRouteEnter(to, from, next) {
    try {
      let household = {};
      let clients = [];
      let cities = [];
      if (to.params.householdId != 0) {
        household = (await Vue.$householdService.findOne(to.params.householdId)).data;
        clients = await Vue.$clientService.find({
          params: {
            householdId: to.params.householdId
          }
        });

        cities = (await Vue.$cityService.find({ params: { state: household.state.name } })).data;
        clients = Vue.$clientService.map(clients);
      }

      let user = await Vue.$accountService.current();
      let agencies = user.isAdmin ? await Vue.$agencyService.find() : [];
      let states = await Vue.$stateService.find();

      return next(vm => {
        vm.clients = clients;
        vm.agencies = agencies.data;
        vm.household = household;
        vm.states = states.data;
        vm.cities = cities;
        vm.user = user;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      return next(false);
    }
  },
  data() {
    return {
      clients: [],
      cities: [],
      deleteDialog: false,
      deleteClient: {},
      headers: [
        { text: "Actions", sortable: false, value: "id" },
        { text: "Name SSN Code", value: "code" },
        { text: "First Name", value: "fullName" },
        { text: "Last Name", value: "lastName" },
        { text: "Gender", value: "genderName" },
        { text: "DOB", value: "dob" },
        { text: "SSN", value: "ssn" },
        { text: "Intake Date", value: "intakeDate" },
        { text: "Comments", value: "comments" },
        { text: "Agency", value: "agencyName" }
      ],
      household: {},
      states: [],
      search: "",
      sending: false,
      user: {}
    };
  },
  methods: {
    async doDelete(client) {
      this.send(true);

      try {
        let res = await Vue.$clientService.delete(client);
        this.send(false);
        this.deleteDialog = false;
        Vue.$eventBus.$emit("notifyUser", "Client Deleted Successfully!");
        this.$router.go();
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    promptDelete(client) {
      this.deleteDialog = true;
      this.deleteClient = client;
    },
    async getCities(state, removeSelected) {
      this.send(true);
      this.cities = [];
      if (removeSelected) {
        this.household.city = null;
      }

      try {
        let cities = await Vue.$cityService.find({ params: { state: state } });
        this.cities = cities.data;
        this.send(false);
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Error loading cities!");
      }
    },
    async save(goBack) {
      try {
        let result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.send(true);

      try {
        let res = await Vue.$householdService.save(this.household);
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Household Saved Successfully!");

        if (goBack) {
          this.$router.go(-1);
        } else {
          if (!this.household.id) {
            this.household = res.data;
            this.$router.replace({
              name: "household",
              params: {
                householdId: this.household.id
              }
            });
          }
        }
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    selectState() {
      this.getCities(this.household.state.name, true);
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    }
  }
};
</script>