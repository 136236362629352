<template>
  <div>
    <v-form v-on:submit.prevent="submitPassword">
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm6>
            <v-text-field
              prepend-icon="lock"
              label="Password"
              type="password"
              :disabled="sending"
              v-validate="'required|min:6|verify_password'"
              :error-messages="errors.collect('Password')"
              data-vv-name="Password"
              ref="password"
              required
              v-model="password"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6>
            <v-text-field
              prepend-icon="lock"
              label="Confirm Password"
              type="password"
              :disabled="sending"
              v-validate="'confirmed:password'"
              :error-messages="errors.collect('Confirm Password')"
              data-vv-name="Confirm Password"
              data-vv-as="Password"
              required
              v-model="confirmPassword"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="text-xs-right primary">
            <v-btn flat type="submit" color="white" :disabled="sending">Save</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "PasswordSection",
  props: [],
  data() {
    return {
      confirmPassword: null,
      password: null,
      sending: false
    };
  },
  methods: {
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    },
    async submitPassword() {
      try {
        let result = await this.$validator.validate();
        if (!result) {
          return false;
        }
      } catch (err) {
        return;
      }

      this.send(true);

      try {
        await Vue.$accountService.savePassword({ password: this.password });
        this.send(false);
        this.password = null;
        this.confirmPassword = null;
        this.$nextTick(() => this.$validator.reset());
        Vue.$eventBus.$emit("notifyUser", "Password Updated!");
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit(
          "notifyUser",
          "There was an issue updating your password"
        );
      }
    }
  }
};
</script>
