var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: { fixed: "", app: "", "clipped-left": "", color: "accent" }
            },
            [
              _c("v-toolbar-side-icon", {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              }),
              _vm._v(" "),
              _c("v-toolbar-title", [_vm._v("WNY Toys")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("div", { staticClass: "sponsor-text" }, [
                _vm._v("\n        Software Sponsored by\n      ")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "sponsor-image",
                attrs: { src: require("../shared/images/wc-logo.png") }
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-avatar", { attrs: { color: "error" } }, [
                _vm.user
                  ? _c("span", { staticClass: "white--text headline" }, [
                      _vm._v(_vm._s(_vm.formatAvatar(_vm.user)))
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.sending
            ? _c("v-progress-linear", {
                staticClass: "my-progress",
                attrs: { height: "5", indeterminate: true }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { display: "flex", "flex-direction": "column" },
              attrs: { fixed: "", clipped: "", app: "" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-tile",
                    { attrs: { to: { name: "households" } } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("home")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Households")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile",
                    { attrs: { to: { name: "clients" } } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("person")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Clients")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.user.isAdmin ? _c("v-divider") : _vm._e(),
                  _vm._v(" "),
                  _vm.user.isAdmin
                    ? _c("v-list-tile", [_vm._v("Admin")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.isAdmin
                    ? _c(
                        "v-list-tile",
                        { attrs: { to: { name: "users" } } },
                        [
                          _c(
                            "v-list-tile-action",
                            [_c("v-icon", [_vm._v("face")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [_c("v-list-tile-title", [_vm._v("Users")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.isAdmin
                    ? _c(
                        "v-list-tile",
                        { attrs: { to: { name: "agencies" } } },
                        [
                          _c(
                            "v-list-tile-action",
                            [_c("v-icon", [_vm._v("domain")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [_c("v-list-tile-title", [_vm._v("Agencies")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        href: "https://account.allco.app/myAccount/preferences"
                      }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("settings")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Account Management")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile",
                    {
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("exit_to_app")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Log Out")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-list",
                { staticStyle: { "margin-bottom": "45px" } },
                [
                  _c(
                    "v-list-tile",
                    { attrs: { href: "https://organization.allco.app" } },
                    [
                      _c("v-list-tile-action", [
                        _c("img", {
                          staticClass: "allco-icon",
                          attrs: {
                            src: require("../shared/images/allco-icon.png")
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Go To Your Allco Profile")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-content",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "fill-height": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", "align-top": "" } },
                    [_c("v-flex", [_c("router-view")], 1)],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-footer",
            { staticStyle: { height: "80px" }, attrs: { app: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "text-xs-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _vm._v(
                      "\n          ©" +
                        _vm._s(_vm.today.getFullYear()) +
                        " —\n          "
                    ),
                    _c("strong", [
                      _vm._v("The Western New York Holiday Partnership")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _vm._v("\n          Created by "),
                    _c("a", { attrs: { href: "https://rprt.dev" } }, [
                      _vm._v("rprt")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("img", {
                      staticClass: "rprt-logo",
                      attrs: { src: require("../shared/images/rprt-logo.webp") }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "" },
          model: {
            value: _vm.snackbar.show,
            callback: function($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show"
          }
        },
        [_vm._v(_vm._s(_vm.snackbar.message))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }