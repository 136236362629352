export default {
    install: (Vue) => {
        Vue.$eventBus = new Vue();

        Vue.axios.interceptors.request.use(request => {
            Vue.$eventBus.$emit('showSending', true);
            return request;
        }, error => {
            Vue.$eventBus.$emit('notifyUser', 'Data Load Issue');
            Vue.$eventBus.$emit('showSending', false);
            return Promise.reject(error);
        });

        Vue.axios.interceptors.response.use(response => {
            Vue.$eventBus.$emit('showSending', false);
            return response;
        }, error => {
            Vue.$eventBus.$emit('notifyUser', 'Data Load Issue');
            Vue.$eventBus.$emit('showSending', false);
            return Promise.reject(error);
        });
    }
};