import moment from 'moment';

export default {
  install: (Vue) => {
    const route = '/api/client/';
    const put = (id, client, params) => Vue.axios.put(`${route}${id}`, client, params);
    const post = (client, params) => Vue.axios.post(route, client, params);

    Vue.$clientService = {
      map(clients) {
        clients = clients && clients.data ? clients.data.rows : clients;

        if (clients) {
          clients.map(client => {
            client.genderName = client.gender ? client.gender.name : '';
            client.householdName = client.household ?
              `${client.household.firstName} ${client.household.lastName}` :
              '';
            client.fullName = `${client.firstName} ${client.lastName}`;
            client.intakeDate = moment.utc(String(client.intakeDate)).format(
              'MM/DD/YYYY'
            );
            client.dob = moment.utc(String(client.dob)).format('MM/DD/YYYY');
            client.agencyName = client.agency ? client.agency.name : '';

            return client;
          });
        }

        return clients;
      },

      find(params) {
        return Vue.axios.get(route, params);
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      },

      save(client, params) {
        if (client.gender) client.genderId = client.gender.id;

        if (client.id) {
          return put(client.id, client, params);
        }

        return post(client, params);
      },

      delete(client) {
        return Vue.axios.delete(`${route}${client.id}`);
      },
    };
  }
};