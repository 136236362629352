export default {
  install: (Vue) => {
    //private
    const route = '/api/city/';

    //public
    Vue.$cityService = {
      find(params) {
        return Vue.axios.get(route, params);
      },

      findOne(id, params) {
        return Vue.axios.get(`${route}${id}`, params);
      }
    };
  }
};