export default {
  install: (Vue) => {
    //private
    const userRoute = '/account/management/user';
    const authRoute = '/account/auth';
    const registrationRoute = '/account/registration/register';

    //public
    Vue.$accountService = {
      current() {
        return JSON.parse(sessionStorage.getItem('user'));
      },
      forgetUser() {
        window.sessionStorage.removeItem('user');
      },
      forgot(email) {
        return Vue.axios.post(`${authRoute}/forgot`, {
          email
        });
      },
      async login(email, password) {
        const res = await Vue.axios.post(`${authRoute}/login`, {
          email,
          password
        });
        window.sessionStorage.setItem(
          'accessToken',
          JSON.stringify(res.data.token)
        );
        window.sessionStorage.setItem(
          'user',
          JSON.stringify(res.data.user)
        );

        return res;
      },
      logout(_this) {
        window.sessionStorage.removeItem('accessToken');
        window.sessionStorage.removeItem('user');

        // clear the service
        _this.accessToken = null;

        // clear the default http headers
        delete _this.$http.defaults.headers.common['x-access-token'];

        window.location.href = '/logout';
      },
      async questions(passwordResetToken) {
        return Vue.axios.get(`${authRoute}/getQuestions`, {
          params: {
            passwordResetToken
          }
        });
      },
      register(user, captchaCode) {
        user.captchaCode = captchaCode;
        return Vue.axios.post(registrationRoute, user);
      },
      reset(passwordResetToken, questions, password) {
        return Vue.axios.post(`${authRoute}/reset`, {
          passwordResetToken,
          questions,
          password
        });
      },
      save(user, params) {
        window.sessionStorage.setItem(
          'user',
          JSON.stringify(user)
        );
        Vue.$eventBus.$emit('updateUser');
        return Vue.axios.put(userRoute, user, params);
      },
      savePassword(user, params) {
        return Vue.axios.put(userRoute, user, params);
      }
    };
  }
};