<template>
  <v-card>
    <v-card-title>
      <v-btn small flat icon :to="{ name: 'agencies'}">
        <v-icon medium>arrow_left</v-icon>
      </v-btn>
      <span class="title font-weight-light">Back to Agencies</span>
    </v-card-title>
    <v-card-text>
      <h3 class="headline" style="text-align: center">{{agency.name}}</h3>
      <v-form v-on:submit.prevent="save">
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Source Code"
                type="text"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('Source Code')"
                data-vv-name="Source Code"
                required
                v-model="agency.sourceCode"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Name"
                type="text"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('Name')"
                data-vv-name="Name"
                required
                v-model="agency.name"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Phone"
                type="tel"
                :disabled="sending"
                mask="phone"
                v-validate="'required'"
                :error-messages="errors.collect('Phone')"
                data-vv-name="Phone"
                required
                v-model="agency.phoneNumber"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Extension"
                type="tel"
                :disabled="sending"
                v-model="agency.phoneExtension"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Address 1"
                type="text"
                :disabled="sending"
                v-model="agency.addressLine1"
                v-validate="'required'"
                :error-messages="errors.collect('Address Line 1')"
                data-vv-name="Address Line 1"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Address 2"
                type="text"
                :disabled="sending"
                v-model="agency.addressLine2"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-autocomplete
                v-model="agency.state"
                :items="states"
                item-text="name"
                label="State"
                persistent-hint
                return-object
                @change="selectState()"
                :disabled="sending"
                v-validate="'required'"
                :error-messages="errors.collect('State')"
                data-vv-name="State"
                required
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-autocomplete
                v-model="agency.city"
                :items="cities"
                item-text="name"
                label="City"
                persistent-hint
                return-object
                :disabled="sending || !agency.state"
                v-validate="'required'"
                :error-messages="errors.collect('State')"
                data-vv-name="State"
                required
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                label="Zip"
                type="text"
                :disabled="sending"
                v-model="agency.zip"
                maxlength="5"
                v-validate="'required'"
                :error-messages="errors.collect('Zip')"
                data-vv-name="Zip"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 class="text-xs-right primary flex-button-container">
              <v-btn flat type="submit" color="white" :disabled="sending">Save</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped>
</style>

<script>
import Vue from "vue";

export default {
  name: "user",
  async beforeRouteEnter(to, from, next) {
    try {
      let agency = {};
      if (to.params.agencyId != 0)
        agency = await Vue.$agencyService.findOne(to.params.agencyId);
      let states = await Vue.$stateService.find();

      return next(vm => {
        vm.agency = agency.data ? agency.data : {};
        vm.states = states.data;
      });
    } catch (err) {
      console.log(err);
      Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      next(false);
    }
  },
  data() {
    return {
      agency: {},
      cities: [],
      states: [],
      sending: false
    };
  },
  methods: {
    async getCities(state, removeSelected) {
      this.send(true);
      this.cities = [];
      if (removeSelected) {
        this.agency.city = null;
      }

      try {
        let cities = await Vue.$cityService.find({ params: { state: state } });
        this.cities = cities.data;
        this.send(false);
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Error loading cities!");
      }
    },
    async save() {
      try {
        let result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.send(true);

      try {
        let res = await Vue.$agencyService.save(this.agency);
        console.log(res);
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Agency Saved Successfully!");
        this.$router.go(-1);
      } catch (err) {
        this.send(false);
        Vue.$eventBus.$emit("notifyUser", "Uh oh, something went wrong!");
      }
    },
    selectState() {
      this.getCities(this.agency.state.name, true);
    },
    send(sending) {
      this.sending = sending;
      Vue.$eventBus.$emit("showSending", sending);
    }
  },
  watch: {
    agency: function(a) {
      if (a.state && a.state.name) this.getCities(a.state.name, false);
    }
  }
};
</script>