var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return _c(
            "v-card",
            { class: "elevation-" + (hover ? 12 : 2) },
            [
              _c("v-card-title", [
                _c("div", [
                  _c("div", { staticClass: "headline" }, [
                    _vm._v("404 - Page Not Found")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "grey--text" }, [_vm._v("APIC")])
                ])
              ]),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "body-2" }, [
                _vm._v("Sorry we could not find that page.")
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        color: "primary",
                        to: { name: "account" }
                      }
                    },
                    [_vm._v("Home")]
                  )
                ],
                1
              )
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }